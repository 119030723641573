@import 'src/assets/styles/theme';

.main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100% - #{$nav-bar-size});
    padding: 15px 25px;

    &.center {
        justify-content: center;

        app-report-placeholder {
            bottom: initial;
        }
    }

    > div {
        width:100%;
        height:100%;
        display: flex;
        flex-direction: column;
    }

    .chart-parent-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        min-height: 0%;
    }

    app-grid {
        overflow: hidden;

        &.multi-report {
            height: calc(50% - 20px);
            width: 100%;
            margin-bottom: 20px;
        }
    }

    app-chart {
        &.multi-report {
            height: 100%;
            width: 50%;
            margin-bottom: 20px;
        }
    }

    app-filters {
        width: 100%;
        z-index: 2;
    }

    app-report-placeholder {
        position: absolute;
        bottom: 20px;
        width: 100%;
    }
}

.collapse-menu{
    margin-left: $menu-collapse;
}

.expand-menu {
    margin-left: $menu-width;
}

.ant-tree-select,
.ant-select-single {
    .ant-select-arrow,
    .ant-select-clear {
        height: 22px;
        width: 24px;
        top: 6px;
        right: 0;
        background-color: $lighter-green;
        color: $darker-green;
        cursor: pointer;

        .anticon {
            margin-top: 5px;
        }
    }
}

nz-select.ant-select-single {
    .ant-select-arrow,
    .ant-select-clear {
        top: 7px;
        right: 1px;
    }
}

.ant-picker {
    width: 100%;
    padding: 0;

    .ant-picker-input>input {
        padding: 0 7px;
        width: auto;
    }

    .ant-picker-suffix {
        display: flex;
        width: 24px;
        height: 24px;
        margin: 0;
        background-color: $lighter-green;
        color: $darker-green;
        justify-content: center;

        .anticon-calendar {
            align-self: center;
        }
    }
}
