//BNPP Square
@font-face {
    font-family: 'BNPP Square Regular';
    src: url('/assets/fonts/bnpp-square/bnpp-square-regular-v3.ttf') format('truetype');
}
@font-face {
    font-family: 'BNPP Square Light';
    src: url('/assets/fonts/bnpp-square/bnpp-square-light-v3.ttf') format('truetype');
}
@font-face {
    font-family: 'BNPP Square Bold';
    src: url('/assets/fonts/bnpp-square/bnpp-square-bold-v3.ttf') format('truetype');
    font-weight: bold;
}

//BNPP Sans
@font-face {
    font-family: 'BNPP Sans Regular';
    src: url('/assets/fonts/bnpp-sans/bnpp-sans.ttf') format('truetype');
}

//Open Sans
@font-face {
    font-family: 'Open Sans Regular';
    src: url('/assets/fonts/open-sans/OpenSans-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'Open Sans Light Italic';
    src: url('/assets/fonts/open-sans/OpenSans-LightItalic.ttf') format('truetype');
    font-style: italic;
}
@font-face {
    font-family: 'Open Sans Semibold';
    src: url('/assets/fonts/open-sans/OpenSans-Semibold.ttf') format('truetype');
}
