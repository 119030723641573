@import 'colors';
@import 'fonts';

$main-color-light: $lighter-green;
$main-color: $green;
$main-color-dark: $darker-green;
$main-color-darkest: $darkest-green;
$main-gradient: linear-gradient(90deg, $dark-green 0%, $light-green 100%);
$main-color-border: $dark-green-grey;
$main-color-font: $darker-green;

$error-color: $red;

$button-color: $dark-green;
$button-color-light: $light-green;
$button-background: $lighter-green;
$button-color-hover: $lighter-green;
$button-background-hover: $dark-green;

$light-bg: $lighter-green;
$lighter-bg: $lightest-green;

$box-shadow: 1px 3px 10px 1px rgba(0,0,0,0.1);
$box-shadow-container: 0px 0px 6px 3px rgba(192, 197, 206, 0.24);
$box-shadow-filter-type: 0 0 14px 1px rgba(0, 141, 115, 0.25);
$box-shadow-report-header: 0 0 8px 1px rgba(192, 197, 206, 0.85);
$box-shadow-selected: 0 0 14px 1px rgba(0,141,115,0.25);

$disable-amount: 20%;
@mixin disable() {
    opacity: $disable-amount;
    pointer-events: none;
}

$nav-bar-top-bar-size: 33px;
$nav-bar-bottom-bar-size: 73px;
$nav-bar-size: $nav-bar-top-bar-size + $nav-bar-bottom-bar-size;

$grid-pagination-size: 25px;
$grid-pagination-spacing: 5px;

$menu-width: 150px;
$menu-collapse: 44px;

$dropdown-list-width: 300px;

$login-bg-gradiant: linear-gradient(270deg, nth($login-gradiants, 1) 0%, nth($login-gradiants, 2) 100%);

$home-box-shadow-tile: 0 0 6px 3px rgba(192, 197, 206, 0.24);
$home-box-shadow-btn: 3px 5px 7px 0 rgba(192, 197, 206, 0.39);
$cash-announcement-gradiant: linear-gradient(270deg, #00BDB4 0%, #008F9E 100%);
$litigation-box-shadow: 0 4px 37px 0 rgba(192, 197, 206, 0.35);
$litigation-bg-value: linear-gradient(239.78deg, #EB3856 0%, #FE7979 100%);
$litigation-bg-green-value: linear-gradient(243.09deg, #24A25F 0%, $home-green 100%);
$litigation-bg-grey-value: linear-gradient(243.09deg, $light-green-grey 0%, $dark-green-grey 100%);
$litigation-box-shadow-value: 0 4px 25px 0 rgba(192, 197, 206, 0.24);
$litigation-icon-bg-demand: linear-gradient(270deg, #D263A6 0%, #7E50A8 100%);
$litigation-icon-bg-unpaid: linear-gradient(270deg, #1D95FF 0%, #0266BF 100%);
$litigation-icon-bg-transactions: linear-gradient(270deg, #ED963C 0%, #EE5C42 100%);
$sales-per-scheme-green-value: linear-gradient(270deg, $home-green 0%, #24A25F 100%);
$sales-per-scheme-box-shadow: 0 2px 6px 0 rgba(0, 201, 118, 0.49);

$loader-bg-color: rgba(128, 128, 128, 0.5);

$chart-tooltip-box-shadow: 0 4px 12px 3px rgba(192, 197, 206, 0.37);

$popperjs-tooltip-box-shadow: 0 4px 12px 3px rgba(192, 197, 206, 0.37);

$contact-box-shadow: 0 2px 6px 3px rgba(0, 73, 60, 0.12);
$contact-title-bg: linear-gradient(90deg, #6FAB8F 0%, $contact-green 100%);

body {
    --main-color: #{$main-color-dark};
    --light-main-color: #{$main-color-light};
    --dark-main-color: #{$main-color-dark};
}

@mixin gradiant($index) {
    $colors: nth($menu-gradiants, $index);
    background: linear-gradient(0.75turn, nth($colors, 1), nth($colors, 2));
}
