@import 'src/assets/styles/theme';

$padding-header: 10px;
$border-width-header: 1px;

angular-slickgrid {
    --slick-header-background-color: $grid-header-green;
}

.dashboard-grid-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-shadow: $box-shadow;
    padding-top: 15px;
    overflow: hidden;

    angular-slickgrid {
        height: 100%;

        .gridPane {
            height: 100% !important;
        }

        .slickgrid-container {
            color: $main-color-font;
            width: 100%;
            height: 100% !important;

            .slick-pane {
                font-family: 'Open Sans', sans-serif;

                &.slick-pane-header {
                    background-color: $grid-header-green;
                    border-top: 1px solid $main-color-border;

                    .slick-header {
                        .slick-header-columns {
                            display: flex;

                            .slick-header-column {
                                display: flex;
                                flex-direction: column;
                                justify-content: space-between;
                                height: initial;
                                padding: $padding-header;
                                color: $main-color-font;
                                cursor: pointer;

                                &:not(:last-child)  {
                                    border: $border-width-header solid $main-color-border;
                                }

                                .slick-column-name {
                                    order: 1;
                                    margin-bottom: 0;
                                    font-size: 12px;
                                    line-height: 17px;
                                    font-family: 'Open Sans SemiBold', sans-serif;
                                }

                                .slick-sort-indicator {
                                    order: 3;
                                    position: initial;
                                    margin: 0;
                                    width: 100%;
                                    text-align: center;
                                    font-family: "icons";
                                    font-size: 20px;
                                    height: 10px;
                                    color: $main-color;

                                    &::before {
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        content: '\f107';
                                        opacity: 0.3;
                                    }

                                    &.slick-sort-indicator-asc {
                                        &::before {
                                            content: '\f107';
                                            opacity: 1;
                                        }
                                    }

                                    &.slick-sort-indicator-desc {
                                        &::before {
                                            content: '\f108';
                                            opacity: 1;
                                        }
                                    }
                                }

                                .slick-header-button {
                                    order: 2;
                                    margin-bottom: 0;
                                    padding-left: calc(100% - 10px);
                                }

                                .info-icon {
                                    font-family: "icons";
                                    &::before {
                                        content: "\f119";
                                    }
                                }
                            }
                        }
                    }

                    .slick-preheader-panel {
                        border-bottom: $border-width-header solid $main-color-border;
                        height: auto;
                        .slick-header-columns {
                            .slick-header-column {
                                padding-top: 5px;
                                padding-right: calc(#{$padding-header} - #{$border-width-header});

                                .slick-column-name {
                                    text-align: center;
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }

                &.slick-pane-top {
                    border-top: 1px solid $main-color-border;


                    .grid-canvas {
                        .slick-row {
                            &.odd {
                                background-color: $grid-row-odd-green;
                            }
                        }

                        .slick-cell {
                            border: none;
                            font-size: 12px;
                            line-height: 14px;
                            font-family: 'Open Sans Regular', sans-serif;

                            &:not(:last-child) {
                                border-right: 1px solid $main-color-border;
                            }
                        }
                    }
                }
            }
        }
    }

    slick-pagination {
        display: none;
    }
}
