@import 'reset';
@import 'colors';
@import 'theme';
@import 'slickgrid';
@import 'modal';
@import 'fonts/icons';
@import 'dynamic-component';

html,
body {
    height: 100%;
    background-color: $body-bg;

    * {
        box-sizing: border-box;
    }

    > app > *:not(router-outlet) {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .hidden {
        display: none !important;
    }

    // popper.js
    .tooltip {

        &.show {
            opacity: 1;
        }

        .arrow::before {
            border-bottom-color: $white;
        }

        .tooltip-inner {
            border-radius: 5px;
            background-color: $white;
            box-shadow: $popperjs-tooltip-box-shadow;
            color: $green;
            font-family: "Open Sans Regular";
            font-size: 10px;
            max-width: 250px;
        }
    }

    .anticon {
        vertical-align: 0;
    }

    @media print {

        body {
            height: initial;
        }

        app-loader {
            display: none !important;
        }

        .main-container {
            margin-left: 0;
            height: 100%;

            .chart-container {
                height: 40%;
            }
        }

        app-grid.multi-report {
            height: 100% !important;
        }

        .top-bar {
            display: none !important;
        }

        .nav-container {
            .current-user, .logout {
                display: none !important;
            }
        }

        .layout-menu-container {
            display: none !important;
        }

        .home-container {
            margin-left: 0 !important;

            .print-btn, .redirect-to {
                display: none !important;
            }

            canvas {
                min-height: 100%;
                max-width: 100%;
                max-height: 100%;
                height: auto !important;
                width: auto !important;
            }
        }

        .back-result-remittance {
            display: none;
        }

        .printing-contract {
            app-filters, .dashboard-grid-container {
                display: none;
            }

            .modal-container {
                position: initial;

                .modal-content {
                    max-width: initial;
                    height: 100%;

                    .btn-print, .close {
                        display: none;
                    }
                }
            }
        }
    }
}

@page {
    size: auto;
}