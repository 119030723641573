//
// Icon Font: icons
//

@font-face {
  font-family: "icons";
  src: url("../../fonts/icons/icons.eot");
  src: url("../../fonts/icons/icons.eot?#iefix") format("embedded-opentype"),
       url("../../fonts/icons/icons.woff2") format("woff2"),
       url("../../fonts/icons/icons.woff") format("woff"),
       url("../../fonts/icons/icons.ttf") format("truetype"),
       url("../../fonts/icons/icons.svg#icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "icons";
    src: url("../../fonts/icons/icons.svg#icons") format("svg");
  }
}

[data-icon]:before { content: attr(data-icon); }

[data-icon]:before,
.i-activity:before,
.i-arrow:before,
.i-arrow-down:before,
.i-arrow-left:before,
.i-arrow-right:before,
.i-arrow-up:before,
.i-change-password:before,
.i-chart:before,
.i-contracts:before,
.i-delete:before,
.i-delete-filled:before,
.i-demand:before,
.i-email:before,
.i-excel:before,
.i-eye:before,
.i-eye-hide:before,
.i-fax:before,
.i-grid:before,
.i-help:before,
.i-home:before,
.i-info:before,
.i-litiges:before,
.i-logout:before,
.i-long-arrow-right:before,
.i-nodata:before,
.i-pdf:before,
.i-perimeter:before,
.i-phone:before,
.i-print:before,
.i-risks:before,
.i-search:before,
.i-sort_asc:before,
.i-sort_desc:before,
.i-statistics:before,
.i-support:before,
.i-transactions:before,
.i-treso:before,
.i-triangle-right:before,
.i-unpaid:before,
.i-user:before,
.i-waiting:before,
.i-warning:before {
  display: inline-block;
  font-family: "icons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

.i-activity:before { content: "\f110"; }
.i-arrow:before { content: "\f117"; }
.i-arrow-down:before { content: "\f105"; }
.i-arrow-left:before { content: "\f103"; }
.i-arrow-right:before { content: "\f104"; }
.i-arrow-up:before { content: "\f106"; }
.i-change-password:before { content: "\f118"; }
.i-chart:before { content: "\f12c"; }
.i-contracts:before { content: "\f111"; }
.i-delete:before { content: "\f129"; }
.i-delete-filled:before { content: "\f138"; }
.i-demand:before { content: "\f120"; }
.i-email:before { content: "\f130"; }
.i-excel:before { content: "\f11d"; }
.i-eye:before { content: "\f135"; }
.i-eye-hide:before { content: "\f136"; }
.i-fax:before { content: "\f131"; }
.i-grid:before { content: "\f12d"; }
.i-help:before { content: "\f134"; }
.i-home:before { content: "\f112"; }
.i-info:before { content: "\f119"; }
.i-litiges:before { content: "\f113"; }
.i-logout:before { content: "\f11a"; }
.i-long-arrow-right:before { content: "\f123"; }
.i-nodata:before { content: "\f100"; }
.i-pdf:before { content: "\f11e"; }
.i-perimeter:before { content: "\f125"; }
.i-phone:before { content: "\f132"; }
.i-print:before { content: "\f12b"; }
.i-risks:before { content: "\f114"; }
.i-search:before { content: "\f137"; }
.i-sort_asc:before { content: "\f107"; }
.i-sort_desc:before { content: "\f108"; }
.i-statistics:before { content: "\f115"; }
.i-support:before { content: "\f12f"; }
.i-transactions:before { content: "\f126"; }
.i-treso:before { content: "\f116"; }
.i-triangle-right:before { content: "\f11b"; }
.i-unpaid:before { content: "\f128"; }
.i-user:before { content: "\f11c"; }
.i-waiting:before { content: "\f12a"; }
.i-warning:before { content: "\f12e"; }

$font-icons-activity: "\f110";
$font-icons-arrow: "\f117";
$font-icons-arrow-down: "\f105";
$font-icons-arrow-left: "\f103";
$font-icons-arrow-right: "\f104";
$font-icons-arrow-up: "\f106";
$font-icons-change-password: "\f118";
$font-icons-chart: "\f12c";
$font-icons-contracts: "\f111";
$font-icons-delete: "\f129";
$font-icons-delete-filled: "\f138";
$font-icons-demand: "\f120";
$font-icons-email: "\f130";
$font-icons-excel: "\f11d";
$font-icons-eye: "\f135";
$font-icons-eye-hide: "\f136";
$font-icons-fax: "\f131";
$font-icons-grid: "\f12d";
$font-icons-help: "\f134";
$font-icons-home: "\f112";
$font-icons-info: "\f119";
$font-icons-litiges: "\f113";
$font-icons-logout: "\f11a";
$font-icons-long-arrow-right: "\f123";
$font-icons-nodata: "\f100";
$font-icons-pdf: "\f11e";
$font-icons-perimeter: "\f125";
$font-icons-phone: "\f132";
$font-icons-print: "\f12b";
$font-icons-risks: "\f114";
$font-icons-search: "\f137";
$font-icons-sort_asc: "\f107";
$font-icons-sort_desc: "\f108";
$font-icons-statistics: "\f115";
$font-icons-support: "\f12f";
$font-icons-transactions: "\f126";
$font-icons-treso: "\f116";
$font-icons-triangle-right: "\f11b";
$font-icons-unpaid: "\f128";
$font-icons-user: "\f11c";
$font-icons-waiting: "\f12a";
$font-icons-warning: "\f12e";
