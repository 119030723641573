$white: #FFFFFF;
$lightest-grey: #F5F5F5;
$light-grey: #E8EEF7;
$grey: #CCCCCC;
$black: #000000;

$red: #EB3856;

$lightest-green: #F6FCFA;
$lighter-green: #C7E9DD;
$light-green-grey: #ACC5C0;
$light-green: #4CB795;
$green: #309A65;
$dark-green: #217445;
$dark-green-grey: #68b7a175;
$darker-green: #12493C;
$darkest-green: #00493C;
$blue: #1C89E8;

$body-bg: #F7F9F9;

$modal-bg: rgba(152, 174, 170, 0.2);

$warning-message :#FFEFE1;
$warning-background :#D96700;

//Too specific colors to use anywhere else, do not use outside angular-slickgrid
$grid-header-green: #D0ECE2;
$grid-row-odd-green: #EBF7F3;

//Too specific colors to use anywhere else, do not use outside menu
$menu-border-color: #426B65;
$menu-gradiants: (
    (#4CB795, #117445),
    (#78D34C, #24BF4D),
    (#D263A6, #7E50A8),
    (#00BDB4, #008F9E),
    (#20C5F2, #017DEB),
    (#1D95FF, #0266BF),
    (#ED963C, #EE5C42),
    (#697CC4, #4A5DA3),
    (#7894FF, #A4B7FF)
);

//Too specific colors to use anywhere else, do not use outside login
$login-gradiants: (#4BBD9B, #00BC8C);
$login-input-bg: rgb(51,161, 125);

//Too specific colors to use anywhere else, do not use outside footer
$footer-color: #49BD9B;

//Too specific colors to use anywhere else, do not use outside home
$home-green: #68DB79;
$home-sales-per-scheme-img: #EDF4FF;
$home-sales-per-scheme-img-disabled: #DBE4F3;
$home-sales-per-scheme-border: #E9E7EB;
$home-volume-color: #55CB72;
$home-amount-color: $blue;
$not-available-data-bg-color: #F7F9F9;
$not-available-data-color: #A4B2AF;

//Too specific colors to use anywhere else, do not use outside contact
$contact-green: #4CB795;
