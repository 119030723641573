@import 'src/assets/styles/theme';

.modal-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    left: 0;
    background-color: $modal-bg;

    .modal-content {
        max-width: 95%;
        margin: auto;
        background-color: $white;
        box-shadow: $box-shadow;
    }
}
